import Vue from 'vue';
import searchAutocomplete from 'components/searchAutocomplete';

const homeSearch = document.querySelector('#vue-home-search');
if (homeSearch) {
    new Vue({
        el: homeSearch,
        render(createElement) {
            return createElement(searchAutocomplete, {
                props: {
                    inputSelector: '.vue-homeSearch__input',
                    widthSelector: '.homeSearch',
                },
                class: 'autocompleteList--posTop z-index-4 searchAutocompleteList--maxHeight150',
            });
        },
    });
}
