import Glide from '@glidejs/glide';
import { deepmerge } from 'tools/deepmerge';
import { LazyLoader, UNVEIL_EVENT } from 'tools/lazyLoader/lazyLoader';
import Translator from 'tools/trans';
import { setDeepTabIndexHidden } from 'tools/setDeepTabIndexHidden';

const DEFAULT_CONFIG = {
    type: 'carousel',
    perView: 1,
    focusAt: 'center',
};

const GLIDE_COMPONENTS = {
    StatistaSlider(Glide, Components, Events) {
        const extension = {
            initControls(Components) {
                if (Components.Controls.items[0]) {
                    const controls = Components.Controls.items[0].childNodes;
                    const controlLeft = this.getControl(controls, '<');
                    const controlRight = this.getControl(controls, '>');
                    if (controlLeft && controlRight) {
                        controlLeft.setAttribute('aria-label', Translator.trans('SLIDER_PREVIOUS'));
                        controlRight.setAttribute('aria-label', Translator.trans('SLIDER_NEXT'));
                    }
                }
            },
            initLazyLoad() {
                new LazyLoader('img.lazy, img.glide__lazy', true, false);
            },
            unveilImages(Components) {
                const lazyImages = Components.Html.wrapper.querySelectorAll('img.lazy, img.glide__lazy');
                lazyImages.forEach(image => image.dispatchEvent(UNVEIL_EVENT));
            },
            initSlider(Components) {
                Components.Html.slides.forEach((slide, index) => {
                    slide.setAttribute('aria-label', `${ index + 1 } ${ Translator.trans('SLIDER_OF') } ${ Components.Html.slides.length }`);
                });
                Components.Html.wrapper.setAttribute('aria-live', 'polite');
            },
            changeSlide(Glide, Components) {
                setAriaAttributes(Glide, Components);

                if (Glide.settings.type !== 'carousel' && Components.Controls.items[0]) {
                    const controls = Components.Controls.items[0].childNodes;
                    const controlLeft = this.getControl(controls, '<');
                    const controlRight = this.getControl(controls, '>');

                    if (controlLeft && controlRight) {
                        controlLeft.style.transition = 'opacity .3s';
                        controlRight.style.transition = 'opacity .3s';
                        if (Glide.index === 0) {
                            controlLeft.blur();
                            controlLeft.classList.add('pointerEvent--none');
                            controlLeft.classList.add('opacity-50');
                        } else {
                            controlLeft.classList.remove('pointerEvent--none');
                            controlLeft.classList.remove('opacity-50');
                        }
                        if (Glide.index === Components.Html.slides.length - 1) {
                            controlRight.blur();
                            controlRight.classList.add('pointerEvent--none');
                            controlRight.classList.add('opacity-50');
                        } else {
                            controlRight.classList.remove('pointerEvent--none');
                            controlRight.classList.remove('opacity-50');
                        }
                    }
                }
            },
            getControl(controls, dir) {
                let dirControl;
                controls.forEach(control => {
                    if (control.nodeType === 1 && control.dataset.glideDir === dir) {
                        dirControl = control;
                    }
                });

                return dirControl;
            },
        };

        Events.on(['mount.after'], () => {
            extension.initSlider(Components);
            extension.initControls(Components);
            extension.initLazyLoad();
            extension.changeSlide(Glide, Components);
        });

        Events.on(['run.after'], () => {
            extension.unveilImages(Components);
            extension.changeSlide(Glide, Components);
        });

        return extension;
    },
};

const DEFAULT_SLIDER = {
    DefaultSlider(Glide, Components, Events) {
        const extension = {
            initSlides(Glide, Components) {
                Components.Clones.items.forEach(item => {
                    setDeepTabIndexHidden(true, item);
                });
            },
            changeSlide(Glide, Components) {
                setAriaAttributes(Glide, Components);
            },
        };
        Events.on(['mount.after'], () => {
            extension.changeSlide(Glide, Components);
            extension.initSlides(Glide, Components);
        });
        Events.on(['run.after'], () => {
            extension.changeSlide(Glide, Components);
        });

        return extension;
    },
};

function setAriaAttributes(Glide, Components) {
    const visibleSlides = getVisibleSlides(Glide, Components);
    const isFocusAtCenter = Glide.settings.focusAt === 'center';

    Components.Clones?.items?.forEach(clone => {
        setDeepTabIndexHidden(true, clone);
        if (isFocusAtCenter) {
            clone.classList.add('pointerEvent--none');
        }
    });
    Components.Html.slides.forEach(slide => {
        setDeepTabIndexHidden(true, slide);
        if (isFocusAtCenter) {
            slide.classList.add('pointerEvent--none');
        }
    });
    visibleSlides.forEach(slide => {
        setDeepTabIndexHidden(false, slide);
        if (isFocusAtCenter) {
            slide.classList.remove('pointerEvent--none');
        }
    });
}

function getVisibleSlides(Glide, Components) {
    const visibleSlides = [];
    const currentSlide = Components.Html.slides[Glide.index];
    const previousSlide = currentSlide.previousElementSibling;
    const nextSlide = currentSlide.nextElementSibling;

    if (previousSlide) {
        if (previousSlide.classList.contains('glide__slide--active')) {
            visibleSlides.push(previousSlide);
        }
    }
    if (nextSlide) {
        if (nextSlide.classList.contains('glide__slide--active')) {
            visibleSlides.push(nextSlide);
        }
    }

    visibleSlides.push(currentSlide);

    return visibleSlides;
}

function initGlide(root, customConfig = {}, customComponents = DEFAULT_SLIDER) {
    const config = deepmerge(DEFAULT_CONFIG, customConfig, { clone: true });
    if (root) {
        return new Glide(root, config).mount(deepmerge(GLIDE_COMPONENTS, customComponents, { clone: true }));
    } else {
        return false;
    }
}

export { initGlide };
