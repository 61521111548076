import axios from 'axios';
import Translator from 'tools/trans';
import { toggleModal } from 'tools/modals';

const VIDEO_TOGGLERS = document.querySelectorAll('[data-video-toggle]');

VIDEO_TOGGLERS.forEach(videoToggler => {
    videoToggler.addEventListener('click', event => {
        event.preventDefault();
        toggleVideoModal(videoToggler.dataset.videoToggle, videoToggler.dataset.modalId);
    });
});

function toggleVideoModal(videoId, videoModal) {
    const videoContainer = document.querySelector(`#${ videoModal } [data-modal-video]`);
    const videoUrl = encodeURI(`https://vimeo.com/${ videoId }`);
    videoContainer.innerHTML = `<div class="cssPreloader--video">
                                    <div class="cssPreloader">
                                        <div class="cssPreloader__inner"></div>
                                    </div>
                                    <div class="h5 subline text-center">
                                        ${ Translator.trans('PLEASE_WAIT') }
                                    </div>
                                </div>`;
    toggleModal(videoModal, 'open', { beforeShow: () => {
        axios.get(`https://vimeo.com/api/oembed.json?url=${ videoUrl }&autoplay=true`).then(response => {
            videoContainer.innerHTML = response.data.html;
        }).catch(response => {
            console.error(response);
        });
    }, beforeClose: () => {
        videoContainer.innerHTML = '';
    } });
}
