import { isMobile } from 'tools/isMobile';
import { isIE } from 'tools/isUserAgent';

const GLIDE_CONFIG = (isMobile() || isIE()) ? {
    perView: 1,
    focusAt: 'center',
} : {
    type: 'slider',
    perView: 5,
    focusAt: 'center',
    gap: 0,
    rewind: false,
};

const GLIDE_COMPONENTS = (isMobile() || isIE()) ? {} : {
    Coverflow: (Glide, Components, Events) => {
        const Plugin = {
            tilt(element) {
                this.tiltPrevElements();
                this.tiltNextElements();
                const glideOpacityElement = element.querySelector('.glide__opacity');
                if (glideOpacityElement) {
                    glideOpacityElement.style.opacity = '1';
                }
                const nonTransformElement = element.classList.contains('glide__non-transform');
                if (!nonTransformElement) {
                    element.style.transform = 'translateZ(0px)';
                }
            },
            tiltPrevElements() {
                const activeSlide = Components.Html.slides[Glide.index];
                const previousElements = [];
                const getPrevious = (element) => {
                    const e = element.previousElementSibling;
                    if (e) {
                        previousElements.push(e);
                        getPrevious(e);
                    }
                };
                getPrevious(activeSlide);
                this.setStyles(previousElements);
            },
            tiltNextElements() {
                const activeSlide = Components.Html.slides[Glide.index];
                const nextElements = [];
                const getNext = (element) => {
                    const e = element.nextElementSibling;
                    if (e) {
                        nextElements.push(e);
                        getNext(e);
                    }
                };
                getNext(activeSlide);
                this.setStyles(nextElements);
            },
            setStyles(elements) {
                elements.forEach((element, index) => {
                    const glideOpacityElement = element.querySelector('.glide__opacity');
                    if (glideOpacityElement) {
                        glideOpacityElement.style.opacity = `${ 1 - ((index + 1) * 0.5) }`;
                    }
                    const nonTransformElement = element.classList.contains('glide__non-transform');
                    if (!nonTransformElement) {
                        element.style.transform = `translateZ(-${ ((index + 1) * (index + 1) * 130) }px)`;
                    }
                });
            },
        };

        Events.on(['build.after', 'run'], () => {
            Plugin.tilt(Components.Html.slides[Glide.index]);
        });

        return Plugin;
    },
};

export { GLIDE_CONFIG, GLIDE_COMPONENTS };
