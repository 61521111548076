import { isMobile } from 'tools/isMobile';

import 'components/infographicSlider';
import 'components/videoModal';
import '../../tools/tooltip';
import './components/searchbar';
import './components/statisticsSlider';
if (!isMobile()) {
    import(/* webpackChunkName: "stickyHeader" */ './components/stickyHeader').then(() => undefined);
    import(/* webpackChunkName: "parallaxScreenshots" */ './components/parallaxScreenshots').then(() => undefined);
}
