import Vue from 'vue';
import ToolTip from 'components/tooltip';

const wrapElement = (el, wrapperType = 'div') => {
    const wrapper = document.createElement(wrapperType);
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);

    return wrapper;
};

const getElementAttributes = (element) => {
    const attributes = {};
    if (element.attributes) {
        for (let i = 0; i < element.attributes.length; i += 1) {
            if (!TOOL_TIP_ATTRIBUTES.includes(element.attributes[i].nodeName)) {
                attributes[element.attributes[i].nodeName] = element.attributes[i].nodeValue;
            }
        }
    }

    return attributes;
};

const extractContent = (toolTipAnchor) => {
    const contentText = toolTipAnchor.getAttribute(TOOL_TIP_SELECTOR_CONTENT);
    const contentHeading = toolTipAnchor.getAttribute(TOOL_TIP_HEADING);
    let settings = toolTipAnchor.getAttribute(TOOL_TIP_SELECTOR_SETTINGS);
    settings = settings ? JSON.parse(settings) : undefined;
    const contentElement = toolTipAnchor.querySelector(`[${ TOOL_TIP_SELECTOR_CONTENT }]`);
    let contentSlotAttrs = {};
    let contentSlot = contentElement;

    if (contentElement) {
        toolTipAnchor.removeChild(contentElement);
        contentSlotAttrs = getElementAttributes(contentElement);
    }

    if (settings && settings.target) {
        contentSlot = document.querySelector(settings.target);
    }

    return [contentText, contentHeading, contentSlot, contentSlotAttrs];
};

const createVueToolTip = (toolTipWrapper, toolTipAnchor) => {
    const settings = toolTipAnchor.getAttribute(TOOL_TIP_SELECTOR_SETTINGS);
    const [contentText, contentHeading, contentSlot, contentSlotAttrs] = extractContent(toolTipAnchor);

    TOOL_TIP_ATTRIBUTES.forEach(attr => toolTipAnchor.removeAttribute(attr));
    const defaultSlotAttributes = getElementAttributes(toolTipAnchor);

    new Vue({
        el: toolTipWrapper,
        render(createElement) {
            return createElement(
                ToolTip,
                {
                    props: {
                        content: contentText,
                        heading: contentHeading || '',
                        settings: settings ? JSON.parse(settings) : undefined,
                    },
                    scopedSlots: {
                        default: () => createElement(
                            toolTipAnchor.nodeName,
                            {
                                attrs: defaultSlotAttributes,
                                domProps: {
                                    innerHTML: toolTipAnchor.innerHTML,
                                },
                            }
                        ),
                        content: () => contentSlot ? createElement(
                            contentSlot.nodeName,
                            {
                                attrs: contentSlotAttrs,
                                domProps: {
                                    innerHTML: contentSlot.innerHTML,
                                },
                            }) : undefined,
                    },
                }
            );
        },
    });
};

const TOOL_TIP_SELECTOR = 'data-tooltip';
const TOOL_TIP_HEADING = 'data-heading';
const TOOL_TIP_SELECTOR_CONTENT = 'data-tooltip-content';
const TOOL_TIP_SELECTOR_SETTINGS = 'data-tooltip-settings';
const TOOL_TIP_ATTRIBUTES = [
    TOOL_TIP_SELECTOR,
    TOOL_TIP_SELECTOR_CONTENT,
    TOOL_TIP_SELECTOR_SETTINGS,
];

document.querySelectorAll(`[${ TOOL_TIP_SELECTOR }]`).forEach(toolTipAnchor => {
    const toolTipWrapper = wrapElement(toolTipAnchor);
    createVueToolTip(toolTipWrapper, toolTipAnchor);
});

/**
 * This script will use tooltip.vue to create Vue-Tooltips on HTML-Elements inside twig-templates.
 *
 * There are three data-attributes that can be used:
 * - data-tooltip:
 *      A Vue-Tooltip will be created on this Element
 * - data-tooltip-settings
 *      A settings object for the Vue component. Use JSON encoding, like ='{ "hideArrow": true }'
 * - data-tooltip-content
 *      The tooltip content as String. If the content should be HTML, you can put this attribute onto a child inside
 *      the root tooltip element.
 *
 * Examples:
    <i
         class="fa fa-info-circle opacity-40"
         data-tooltip
         data-tooltip-settings='{ "hideArrow": true }'
         data-tooltip-content="This is a simple tooltip."></i>

    <i
        class="fa fa-info-circle opacity-40"
        data-tooltip
        data-tooltip-settings='{ "hideArrow": true }'>
        <span data-tooltip-content style="color: red;">
            This is a tooltip with HTML content.
        </span>
    </i>

    <span id="someOtherElement">This is a tooltip with some distant content.</span>
     <i
         class="fa fa-info-circle opacity-40"
         data-tooltip
         data-tooltip-settings='{ "target": "#someOtherElement" }'>
     </i>
 */
